<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<!-- 搜索栏 -->
				<a-row>
					<a-form-item label="演出名称" name="performName" class="ui-form__item">
						<a-input v-model:value="formState.performName" placeholder="请输入演出名称"></a-input>
					</a-form-item>
					
					<a-form-item label="票档名称" name="ticketName" class="ui-form__item">
						<a-input v-model:value="formState.ticketName" placeholder="请输入票档名称"></a-input>
					</a-form-item>
					
					<a-form-item label="座位编号" name="seatCode" class="ui-form__item">
						<a-input v-model:value="formState.seatCode" placeholder="请输入座位编号"></a-input>
					</a-form-item>
					
					<a-form-item label="票状态" name="status" class="ui-form__item">
						<a-select v-model:value="formState.status" placeholder="请选择票状态" allowClear style="width: 190px;">
							<a-select-option :value="0">待使用</a-select-option>
							<a-select-option :value="1">已使用</a-select-option>
							<a-select-option :value="2">已过期</a-select-option>
							<a-select-option :value="3">已转赠</a-select-option>
							<a-select-option :value="4">已售后</a-select-option>
							<a-select-option :value="5">未支付</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item label="座位状态" name="seatStatus" class="ui-form__item">
						<a-select v-model:value="formState.seatStatus" placeholder="请选择座位状态" allowClear style="width: 190px;">
							<a-select-option :value="0">未分配</a-select-option>
							<a-select-option :value="1">已分配</a-select-option>
							<a-select-option :value="2">已冻结</a-select-option>
							<a-select-option :value="3">已售后</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item label="订单号" name="orderNo" class="ui-form__item">
						<a-input v-model:value="formState.orderNo" placeholder="请输入订单号"></a-input>
					</a-form-item>
					
					<a-form-item label="用户信息" name="userInfo" class="ui-form__item">
						<a-input v-model:value="formState.userInfo" placeholder="请输入用户信息"></a-input>
					</a-form-item>
					
					<a-form-item label="演出时间" class="ui-form__item">
						<a-range-picker v-model:value="time"></a-range-picker>
					</a-form-item>
				</a-row>

				<a-row>
					<a-col :span="16">
						<span v-permission="['performance_order_seat_export']">
							<exportReport type="performUserTicketExportStrategy" :searchData="searchData"></exportReport>
						</span>
						<!-- <a-button v-permission="['performance_order_seat_import']" type="primary" @click="onImport" style="margin-left: 10px;">分配座位导入</a-button> -->
					</a-col>
					<a-col :span="8" style="text-align: right">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div style="margin-top: 10px;">
				<cTable ref="cTable" style="padding: 0;" :searchData="searchData" :columns="columns" :isPage="true" :isRequest="true" :requestFun="getUserTicketList" :scroll="{ x: 2000 }">
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'showStartTime'">
							<div>
								<div>{{ transDateTime(record.showStartTime) }}</div>
								至
								<div>{{ transDateTime(record.showEndTime) }}</div>
							</div>
						</template>
						<template v-if="column.key === 'divideTime'">
							{{ transDateTime(record.divideTime) }}
						</template>
						<template v-if="column.key === 'user'">
							<div>
								<div>用户名称：{{ record.userNickName || '-' }}</div>
								<div>用户手机号：{{ record.phone || '-' }}</div>
							</div>
						</template>
						<template v-if="column.key === 'payType'">
							<div>
								<a-tag v-if="record.payType === 'wx_lite'" color="#87d068">微信支付</a-tag>
								<a-tag v-if="record.payType === 'alipay'" color="#2db7f5">支付宝支付</a-tag>
								<a-tag v-if="record.payType === 'card'" color="#f50">一卡通支付</a-tag>
							</div>
						</template>
						<template v-if="column.key === 'status'">
							{{ ['待使用', '已使用', '已过期', '已转赠', '已售后', '未支付'][record.status] }}
						</template>
						<template v-if="column.key === 'seatStatus'">
							{{ ['未分配', '已分配', '已冻结', '已售后'][record.seatStatus || 0] }}
						</template>
						<template v-if="column.key === 'sendInfo'">
							<div v-if="record.deliveryType === 3">
								<div>物流公司：{{ record.logisticsCompany }}</div>
								<div>物流单号：{{ record.logisticsNo }}</div>
							</div>
							<div v-else>-</div>
						</template>
						<template v-if="column.key === 'divideType'">
							{{ ['未分配', '已自动分配', '已手动分配'][record.divideType] }}
						</template>
						<template v-if="column.key === 'afterInfo'">
						</template>
						<template v-if="column.key === 'action'">
							<a-dropdown v-if="record.divideType === 0 && record.status == 0" :trigger="['click', 'hover']">
								<a-button>操作 <Icon icon="DownOutlined"></Icon></a-button>
								<template #overlay>
									<a-menu>
										<div v-permission="['performance_order_seat_import']" @click="onAfter(record)">
											<a-menu-item>
												分配座位
											</a-menu-item>
										</div>
									</a-menu>
								</template>
							</a-dropdown>
							<div v-else>-</div>
						</template>
					</template>
				</cTable>
			</div>

			<a-modal v-model:visible="showAfterModal" destroyOnClose title="分配座位" width="500px" @ok="onDivideOk">
				<a-spin :spinning="loading">
					<a-form ref="afterForm" scrollToFirstError :model="afterModelRef" name="afterForm"
						:labelCol="{span: 5}" :wrapperCol="{span: 15 }">
						
						<!-- <a-form-item label="座位号" name="price" :rules="[{required: true, message:'必填项不允许为空'}]">
							<a-input v-model:value="afterModelRef.price"></a-input>
						</a-form-item> -->
						
						<a-form-item label="座位编号" name="seatCode" :rules="[{ required: true, message: '必填项不允许为空' }]">
							<a-input v-model:value="afterModelRef.seatCode"></a-input>
						</a-form-item>
					</a-form>
				</a-spin>
			</a-modal>
			
			<a-modal v-model:visible="importModal" title="分配座位导入" width="759px" @ok="onImportOk">
				<a-spin :spinning="loading">
					<a-form ref="importFormRef" :model="importModelRef" :labelCol="{ span: 5 }" :wrapperCol="{ span: 16 }">
						<div>
							<a-form-item label="任务名称" name="title" :rules="[{required: true, message: '必填项不允许为空'}]">
								<a-input v-model:value="importModelRef.title" placeholder="请输入"></a-input>
							</a-form-item>
							
							<a-form-item label="点击下载">
								<a href="/dashboard/导入演出场次分配座位模板.xlsx">Excel模板</a>
							</a-form-item>
							
							<a-form-item label="选择文件">
								<a-upload v-model:file-list="fileList" list-type="picture" action="/admin/ajaxUpload.do"
										  accept=".xlsx" :max-count="1" :beforeUpload="beforeUpload" @change="onUploadChange">
									<a-button>
										<Icon icon="UploadOutlined"></Icon>
										上传
									</a-button>
								</a-upload>
							</a-form-item>
						</div>
					</a-form>
				</a-spin>
			</a-modal>
		</a-spin>

	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import cTable from '@/components/cTable/index.vue';
	import logisticsCompany from '@/components/logisticsCompany/index.vue';
	import exportReport from '@/components/exportReport/exportReport.vue';
	import { getUserTicketList, dividePerformSeat } from '@/service/modules/perform.js';
	import { sendOrderBase } from '@/service/modules/mall.js';
	import { importTaskSave } from '@/service/modules/task.js';
	export default {
		components: {
			Icon,
			cTable,
			exportReport,
			logisticsCompany
		},
		data() {
			return {
				showSendGoods: false,
				showAfterModal: false,
				loading: false,
				searchData: {},
				afterModelRef: {},
				time: [],
				formState: {},
				columns: [{
					title: '演出',
					dataIndex: 'performName',
				}, {
					title: '订单号',
					dataIndex: 'orderNo',
					width: 170
				}, {
					title: '场次时间',
					key: 'showStartTime'
				}, {
					title: '票状态',
					key: 'status',
					width: 130
				}, {
					title: '用户信息',
					key: 'user',
					width: 220
				}, {
					title: '票档',
					dataIndex: 'ticketName',
				}, {
					title: '票号',
					dataIndex: 'ticketNumber'
				}, {
					title: '座位',
					dataIndex: 'seatName',
					width: 130
				}, {
					title: '座位编号',
					dataIndex: 'seatCode',
					width: 130
				}, {
					title: '座位状态',
					key: 'seatStatus',
					width: 130
				}, 
				// {
				// 	title: '分配类型',
				// 	key: 'divideType'
				// }, 
				{
					title: '分配时间',
					key: 'divideTime',
				}, {
					title: '分配人',
					dataIndex: 'divideUserName',
					width: 170
				}, {
					title: '操作',
					key: 'action',
					fixed: 'right',
					width: 120
				}],
				importModal: false,
				importModelRef: {},
				fileList: []
			}
		},
		mounted() {
			this.onSearch();
		},
		methods: {
			getUserTicketList: getUserTicketList,
			reset() {
				this.time = [];
				this.$refs.form.resetFields();
				this.onSearch();
			},
			onSearch() {
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				if (this.time && this.time.length) {
					this.searchData.showStartTime = this.time[0].startOf('days').unix();
					this.searchData.showEndTime = this.time[1].endOf('days').unix();
				}
				this.getData(true);
			},
			getData(isReset) {
				if (isReset) {
					// 重置分页查询
					this.$refs.cTable.toQuery();
				} else {
					// 不重置分页查询
					this.$refs.cTable.refQuery();
				}
			},
			onAfter(item) {
				this.afterModelRef = {
					// id: item.id,
					phone: item.phone,
					ticketNo: item.ticketNumber,
				}
				this.showAfterModal = true;
			},
			onSendOrder(item) {
				this.modelRef = {
					id: item.id,
					logisticsCompany: undefined,
					logisticsNo: ''
				}
				this.showSendGoods = true;
			},
			onImport() {
				this.importModelRef = {
					type: 'performSeatDivideImportStrategy'
				};
				this.importModal = true;
			},
			onImportOk() {
				this.$refs.importFormRef.validate().then(() => {
					if (this.fileList.length === 0) {
						this.$message.warn('请先选择模板文件');
						return;
					}
					this.$confirm({
						title: '提示',
						content: '确定执行导入吗？',
						onOk: async () => {
							this.loading = true;
							let postData = JSON.parse(JSON.stringify(this.importModelRef));
							let ret = await importTaskSave(postData)
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('任务创建成功，请前往【系统-任务中心-导入任务】页查看导入结果');
								this.importModal = false;
								this.importModelRef = {};
							}
						}
					})
				})
			},
			onDivideOk() {
				this.$confirm({
					title: '提示',
					content: '确定分配吗？',
					onOk: async ()=> {
						this.loading = true;
						let ret = await dividePerformSeat({
							...this.afterModelRef
						})
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success('成功');
							this.showAfterModal = false;
							this.getData();
						}
					}
				})
			},
			onUploadChange(info) {
				if (info.file.status === 'done') {
					//console.log(info.file);
					this.fileList = info.fileList;
					const $fileList = [...this.fileList];
					this.fileList = $fileList.map(file => {
						if (file.response) {
							this.importModelRef.filePath = file.response.data.imgUrl;
							file.url = file.response.data.imgUrl;
						}
						return file;
					});
				}
			},
			beforeUpload(file) {
				const isLt1M = file.size / 1024 / 1024 < 10;
				if (!isLt1M) {
					this.$message.error('上传文件太大了, 最大只允许10M');
				}
				return isLt1M;
			}
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>